<template>
  <div>
    <article>
      <section>
        <div class="destination_dialogs">
          <el-dialog
            :title="`Edit A Destination`"
            :visible.sync="editDestinationModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div
              v-loading="isUploadingFile"
              class="mt-5"
            >
              <el-form
                :model="destinationForm"
                :rules="destinationRules"
                ref="destinationForm"
                class="demo-destinationForm2"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item
                      label="Title"
                      prop="country_title"
                    >
                      <el-input v-model="destinationForm.title"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    
                     
                      <el-form-item prop="description">
                    <editor
                      api-key="m5t9lo16on3qgnsrn3mfypfsi3y72guxr20ma1iqjxrhnzt1"
                      :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                          'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar:
                          'undo redo | casechange blocks | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                      }"
                      placeholder="Please input details here"
                      v-model="destinationForm.description"
                    />
                  </el-form-item>
                  
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Destination Featured image">
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handleFeaturedImagePreview"
                        :on-remove="handleFeaturedImageRemove"
                        :file-list="featuredImageFileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="editDestination('destinationForm')"
                  >Edit Destination</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  components:{
    editor: Editor,
  },
  data() {
    return {
      editDestinationModal: false,

      selectedFeaturedImageFile: null,
      // selectedSlideImageOneFile: null,
      // selectedSlideImageTwoFile: null,
      // selectedSlideImageThreeFile: null,

      isUploadingFile: false,
      value: "",
      loading: false,
      loadingError: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      destinationForm: {
        title: "",
        description: "",
      },

      featuredImageFileLists: [],
      // slideImageOneFileLists: [],
      // slideImageTwoFileLists: [],
      // slideImageThreeFileLists: [],

      destinationRules: {
        title: [
          {
            required: true,
            message: "Title is required",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Description is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  props: {
    countryId: {
      type: String,
      required: true,
    },
    isEditDestinationDialogVisible: {
      type: Boolean,
      required: true,
    },
    safariDestinationData: {
      type: Object,
      required: true,
    },
  },

  watch: {
    isEditDestinationDialogVisible() {
      if (this.isEditDestinationDialogVisible == true) {
        this.editDestinationModal = true;
        this.destinationForm.title = this.safariDestinationData.title;
        this.destinationForm.description =
          this.safariDestinationData.description;
        this.selectedFeaturedImageFile = this.safariDestinationData.image;
        // this.selectedSlideImageOneFile =
        //   this.safariDestinationData.slide_image_1;
        // this.selectedSlideImageOneFile =
        //   this.safariDestinationData.slide_image_2;
        // this.selectedSlideImageOneFile =
        //   this.safariDestinationData.slide_image_3;
      } else {
        this.editDestinationModal = false;
      }
    },
  },

  methods: {
    handleClose(done) {
      done();
      this.$emit("closeEditDestinationDialog");
    },

    handleFeaturedImagePreview(file) {
      console.log(file.raw);
      this.featuredImageFileLists = [file];
      this.selectedFeaturedImageFile = file.raw;
    },

    handleFeaturedImageRemove() {
      this.selectedFeaturedImageFile = null;
    },

    // handleSlideImageOnePreview(file) {
    //   console.log(file.raw);
    //   this.slideImageOneFileLists = [file];
    //   this.selectedSlideImageOneFile = file.raw;
    // },
    // handleSlideImageOneRemove() {
    //   this.selectedSlideImageOneFile = null;
    // },

    // handleSlideImageTwoPreview(file) {
    //   console.log(file.raw);
    //   this.slideImageTwoFileLists = [file];
    //   this.selectedSlideImageTwoFile = file.raw;
    // },
    // handleSlideImageTwoRemove() {
    //   this.selectedSlideImageTwoFile = null;
    // },

    // handleSlideImageThreePreview(file) {
    //   console.log(file.raw);
    //   this.slideImageThreeFileLists = [file];
    //   this.selectedSlideImageThreeFile = file.raw;
    // },
    // handleSlideImageThreeRemove() {
    //   this.selectedSlideImageThreeFile = null;
    // },

    async editDestination(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.selectedFeaturedImageFile == null) {
            return this.$notify({
              title: "Empty",
              message: "Featured is empty",
              type: "warning",
            });
          }

          // if (this.selectedSlideImageOneFile == null) {
          //   return this.$notify({
          //     title: "Empty",
          //     message: "Slide Image One is empty",
          //     type: "warning",
          //   });
          // }

          // if (this.selectedSlideImageTwoFile == null) {
          //   return this.$notify({
          //     title: "Empty",
          //     message: "Slide Image Two is empty",
          //     type: "warning",
          //   });
          // }

          // if (this.selectedSlideImageThreeFile == null) {
          //   return this.$notify({
          //     title: "Empty",
          //     message: "Slide Image Three is empty",
          //     type: "warning",
          //   });
          // }

          try {
            const formData = new FormData();
            formData.append("title", this.destinationForm.title);
            formData.append("description", this.destinationForm.description);
            formData.append("country_id", this.safariDestinationData.countryId);
            formData.append("image", this.selectedFeaturedImageFile);
            // formData.append("slide_image_1", this.selectedSlideImageOneFile);
            // formData.append("slide_image_2", this.selectedSlideImageTwoFile);
            // formData.append("slide_image_3", this.selectedSlideImageThreeFile);

            this.isUploadingFile = true;
            let request = await this.$http.post(
              `api/destination/edit/${this.safariDestinationData.id}`,
              formData,
              {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              }
            );
            if (
              request.data.success &&
              request.data.message == "DESTINATION_EDITED_SUCCESSFULLY"
            ) {
              this.editDestinationModal = false;
              this.$emit("refreshPackages");
              this.$notify({
                title: "Success",
                message: "Destination Edited Successfully",
                type: "success",
              });
            } else if (request.data.message == "safari_tour  exists") {
              this.$notify({
                title: "Duplicate",
                message: "Package Exists",
                type: "warning",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            this.$notify({
              title: "Upload Failed",
              message: "Unable to Upload profile picture now, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },

    showAddPackageModal() {
      this.addPackageModal = true;
      // this.destinationForm.title = "";
      // this.destinationForm.year_of_study = "";
      // this.destinationForm.semester_of_study = "";
      // this.destinationForm.credit_unit = "";
    },
  },
};
</script>

<style>
.destination_dialogs .el-dialog {
  width: 65%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .destination_dialogs .el-dialog {
    width: 65%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .destination_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .destination_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .destination_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
